import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import App from "./App";
import {ToastContainer} from 'react-toastify';


ReactDOM.render(
  <BrowserRouter>
    <App />
    <ToastContainer position="top-left"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover/>
  </BrowserRouter>,
  document.getElementById("root")
);