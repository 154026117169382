import Scores from "./components/Scores";
import { Routes, Route } from "react-router-dom";
import AssessmentPrice from "./components/AssessmentPrice";
import "./App.css";



const App = () => {
  return (
    <Routes>
    <Route path="/" element={<Scores />} />
    <Route path="/assessment" element={<AssessmentPrice />} />
  </Routes>
  );
};

export default App;