import {useState} from 'react';
import styled from 'styled-components';
import {mobile} from "../responsive";
import Result from './StaffAssessmentResult';
import axios from 'axios'; 
import { config } from '../config';
import { toast } from 'react-toastify'; 


const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: teal;
    display: flex;
    align-items: center;
    justify-content: center;
`; 

const Wrapper = styled.div`
    width: 25%;
    padding: 20px;
    background-color: white;
    ${mobile({width: "75%"})}
`;

const Form = styled.form`
        display: flex;
        flex-direction: column;
`;

const Title = styled.h1`
        font-size: 24px;
        font-weight: 300;
`;

const Input = styled.input`
        flex: 1;
        min-width: 30%;
        margin: 10px 0;
        padding: 10px;
`;

const Button = styled.button`
        width: 40%;
        border: none;
        padding: 15px 20px;
        background-color: teal;
        color: white;
        cursor: white;
        margin-bottom: 10px
`;



const AssessmentPrice = () => {
    const [phoneNumber, setphoneNumber] = useState('');
    const [assessmentCheck, setAssessmentCheck] = useState(null);

    const handleChange = (event)=>{
        setphoneNumber(event.target.value);
    }

    const getScore = async ()=>{
         
        try {
            return await axios.get(`${config.apiBaseUrl}/users/assessment/getScore/${phoneNumber}`);
            } catch (error) {
                toast.error(error?.response?.data?.message);
            }
        };

    const handleClick = async (event) =>{
        event.preventDefault();
        const result = await getScore();
        setAssessmentCheck(result);
}
    return (
        <Container>
            { assessmentCheck ? <Result item = {assessmentCheck}/>: <Wrapper>
                <Title>You've Worked Hard, What's Your Price?</Title>
                <Form>
                    <Input placeholder="phone number" value={phoneNumber} name="phoneNumber" onChange={handleChange}/>
                    <Button className="click" onClick={handleClick}>Check</Button>
                </Form>
            </Wrapper>}
        </Container>
    )
}

export default AssessmentPrice

