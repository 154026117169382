import styled from 'styled-components';
import React from 'react';

const Wrapper = styled.section`
  padding: 4em;
  background: papayawhip;
`;

const Title = styled.h1`
  font-size: 1.5em;
  text-align: center;
  color: palevioletred;
`;

const Label = styled.label`
  padding: 0em;
  background: papayawhip;
  display:flex;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: column
`;

const Input = styled.input`
  font-size: 20px;
  width: 300px;
`
const StaffAssessmentResult = ({item:{data}}) => {
      return (
        <div>
            <Wrapper>
                <Title>Assessment Result</Title>
                    <Label> 
                        Price:
                            <Input type="text" value={data?.data?.price} readOnly/>
                        Remark:
                            <Input type="text" value={data?.data?.remark} readOnly/>
                    </Label>
            </Wrapper>
        </div>
    )
   
}

export default StaffAssessmentResult

