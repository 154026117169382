import styled from 'styled-components';
import React from 'react';

const Wrapper = styled.section`
  padding: 4em;
  background: papayawhip;
`;

const Title = styled.h1`
  font-size: 1.5em;
  text-align: center;
  color: palevioletred;
`;

const Label = styled.label`
  padding: 0em;
  background: papayawhip;
  display:flex;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: column
`;

const Input = styled.input`
  font-size: 20px;
`
const Result = ({item:{data}}) => {
      return (
        <div>
            <Wrapper>
                <Title>Vulnerabilty Status</Title>
                    <Label> 
                        Score:
                            <Input type="text" value={data?.data?.score} readOnly/>
                        Status:
                            <Input type="text" value={data?.data?.status} readOnly/>
                    </Label>
            </Wrapper>
        </div>
    )
   
}

export default Result

